import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export default function LiquidatedTag(props) {
  const {
    sx,
    size = 'small',
  } = props;
  const { t } = useTranslation();

  return (
    <Chip
      color="primary"
      label={t('components.LiquidatedTag.label')}
      size={size === 'tiny' ? 'small' : size}
      sx={{
        ...sx,
        fontSize: (theme) => theme.typography.caption.fontSize,
        ...(size === 'tiny' ? {

        } : {}),
      }}
    />
  );
}

LiquidatedTag.propTypes = {
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'large']),
};
