import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import { gql } from '@apollo/client';
import {
  FiArrowRight,
  FiChevronDown,
} from 'react-icons/fi';
import { useQuery } from '@lib/hooks';
import {
  ORGANIZATION_TYPE_BGCOLORS,
  ORGANIZATION_TYPE_COLORS,
} from '@theme';
import { useTranslation } from 'react-i18next';
import LiquidatedTag from '@components/organization/LiquidatedTag';

export default function OrganizationHierarchy(props) {
  const {
    id,
    onlyParent,
    onlyChildren,
    isPublic,
    noPress,
    sx,
    onClick,
  } = props;
  let { currentOrgId } = props;
  currentOrgId = `${currentOrgId}`;
  const { i18n } = useTranslation();
  const { data } = useQuery(FETCH, {
    variables: {
      id,
      locale: i18n.language,
      isPublic,
      noPress,
    },
  });
  const org = data?.organizationHierarchy;

  if (!org) return null;

  if (onlyChildren && org.organizations.length === 0) return null;

  const handleClick = (orgId) => (event) => {
    if (onClick) {
      onClick(event, orgId);
    }
  };

  return (
    <List
      dense
      sx={{
        ...sx,
        whiteSpace: 'normal',
      }}
    >
      {!onlyChildren && (
        <ListItemButton
          onClick={handleClick(org.id)}
          sx={{ pl: 1 }}
        >
          <ListItemIcon>
            {org.id === currentOrgId ? <FiArrowRight /> : <FiChevronDown />}
          </ListItemIcon>
          <ListItemText
            primary={(
              <ItemWrapper isSelected={org.id === currentOrgId}>
                <Box display="flex" justifyContent="space-between">
                  <Typography
                    color={ORGANIZATION_TYPE_COLORS[org.organizationType]}
                    component="div"
                    sx={{
                      bgcolor: ORGANIZATION_TYPE_BGCOLORS[org.organizationType],
                      px: 0.5,
                      borderRadius: '4px',
                    }}
                    variant="subtitle2"
                  >
                    {org[`name_${i18n.language}`]}
                    {org.liquidationState === 'liquidated' && (
                      <LiquidatedTag sx={{ ml: 1 }} />
                    )}
                  </Typography>
                  {org.addressCity && (
                    <Typography sx={{ ml: 1 }} variant="body2">
                      {org.addressCity}
                    </Typography>
                  )}
                </Box>
              </ItemWrapper>
            )}
          />
        </ListItemButton>
      )}
      {!onlyParent && renderChildren(org.organizations, currentOrgId, isPublic, i18n, handleClick)}
    </List>
  );
}

function renderChildren(items, currentOrgId, isPublic, i18n, handleClick, level = 1) {
  return items?.map((item) => (
    <List key={item.id} dense disablePadding>
      <ListItemButton
        onClick={handleClick(item.id)}
        sx={{ pl: 4 * level }}
      >
        <ListItemIcon>
          {item.id === currentOrgId ? <FiArrowRight /> : <FiChevronDown />}
        </ListItemIcon>
        <ListItemText
          primary={(
            <ItemWrapper isSelected={item.id === currentOrgId}>
              <Box display="flex" justifyContent="space-between">
                <Typography
                  color={ORGANIZATION_TYPE_COLORS[item.organizationType]}
                  component="div"
                  sx={{
                    bgcolor: ORGANIZATION_TYPE_BGCOLORS[item.organizationType],
                    px: 0.5,
                    borderRadius: '4px',
                  }}
                  variant="subtitle2"
                >
                  {item[`name_${i18n.language}`]}
                  {item.liquidationState === 'liquidated' && (
                    <LiquidatedTag sx={{ ml: 1 }} />
                  )}
                </Typography>
                {item.addressCity && (
                  <Typography sx={{ ml: 1 }} variant="body2">
                    {item.addressCity}
                  </Typography>
                )}
              </Box>
            </ItemWrapper>
          )}
        />
      </ListItemButton>
      {renderChildren(item.organizations, currentOrgId, isPublic, i18n, handleClick, level + 1)}
    </List>
  ));
}

function ItemWrapper({ isSelected, children }) {
  if (isSelected) {
    return (
      <Paper
        sx={{ p: 1 }}
        variant="outlined"
      >
        {children}
      </Paper>
    );
  }

  return children;
}

const FETCH = gql`
  query Fetch($id: ID!, $locale: String!, $isPublic: Boolean, $noPress: Boolean) {
    organizationHierarchy(id: $id, locale: $locale, isPublic: $isPublic, noPress: $noPress)
  }
`;
